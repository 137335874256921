import axios from "axios";
import FormData from "form-data";
import "react-toastify/dist/ReactToastify.css";
import { language } from "../languages/tr";

const server = axios.create();
server.defaults.baseURL = process.env.NEXT_PUBLIC_API;

//Kelime ile arama
export const SearchApi = async (text) => {
  const { searchMinLength } = language;
  if (text.length < 3) throw { message: searchMinLength };
  return server.get("/Search?q=" + text);
};

//FullscreenMenu
export const GetFullScreenMenu = async () => {
  return server.get("/FullScreenMenu");
};

//Detay Fotoğraflar
export const GetPhotosApi = async (id) => {
  return server.get("/Photos?id=" + id);
};

//Footer Menü
export const GetFooterMenu = async () => {
  return server.get("/FooterMenu");
};

//Arama Motoru
export const FilterApi = async (params) => {
  const strParams = new URLSearchParams(params).toString();
  return server.get("/filter?" + strParams);
};

//Fiyat Hesaplama
export const Calculate = async (params) => {
  const strParams = new URLSearchParams(params).toString();
  return server.get("/Calculate?" + strParams);
};

//Popüler Villalar
export const GetPopularVillas = async (params) => {
  const strParams = new URLSearchParams(params).toString();
  return server.get("/get-popular-villas?" + strParams);
};

//Key'e göre Villalar
export const GetVillasByKey = async (key) => {
  return server.get("/GetVillasByKey?key=" + key);
};

//Benzer Villalar
export const GetSimilarVillas = async (id, destination) => {
  return server.get(
    "/GetSimilarVillas?id=" + id + "&destination=" + destination
  );
};

//Detay Fiyat Tablosu
export const GetPriceList = async (id, currency, start2 = "") => {
  return server.get(
    "/PriceList?id=" + id + "&currency=" + currency + "&start2=" + start2
  );
};

//Talep Gönder
export const SendReservation = async (params, data) => {
  let form_data = new FormData();

  Object.keys(data).forEach((key) => form_data.append(key, data[key]));

  const strParams = new URLSearchParams(params).toString();

  return server.post("/SendReservation?" + strParams, form_data);
};

//Home Story
export const GetStory = async () => {
  return server.get("/Story");
};

export const GetSliderCaption = async () => {
  return server.get("/get-slider-caption");
};

export const GetProductCategories = async () => {
  return server.get("/ProductCategories");
};

export const GetProductDestinations = async () => {
  return server.get("/ProductDestinations");
};

export const GetAvailability = async (EntityId, start2 = "") => {
  return server.get("/Availability?EntityId=" + EntityId + "&start2=" + start2);
};

export const AuthMyReservations = async (Action = "Reservations") => {
  if (localStorage.getItem("reservations_auth"))
    return server.post(
      "/IsLogin?Action=" + Action,
      "token=" + localStorage.getItem("reservations_auth")
    );

  throw { error: "Not Login" };
};

export const AuthLogin = async (
  Email = null,
  ReservationNumber = null,
  AuthKey = null
) => {
  if (AuthKey) return server.post("/Reservations", "AuthKey=" + AuthKey);
  return server.post(
    "/Reservations",
    "Email=" + Email + "&ReservationNumber=" + ReservationNumber
  );
};

export const GavelLogin = async (ReservationNumber = null, Emlak = null) => {
  return server.post(
    "/CheckGavelReservation",
    "ReservationNumber=" + ReservationNumber + "&emlak=" + Emlak
  );
};

export const AuthLogout = async () => {
  return server.post(
    "/Logout",
    "token=" + localStorage.getItem("reservations_auth")
  );
};

export const GetReservations = async (token) => {
  return server.get("/Reservations?token=" + token);
};

export const GetPaymentData = async (token, id, agent = null, ip = null) => {
  const config = {};
  if (agent) {
    config.headers = {
      "User-Agent": agent,
      CLIENT_IP: ip,
    };
  }
  return server.post("/Payment?token=" + token, "id=" + id, config);
};

export const CreatePaymentLink = async (token, id) => {
  return server.post("/Vallet?token=" + token, "id=" + id);
};

export const CreateHashData = async (values) => {
  return server.post("/CreateHashData", values);
};

export const GetPaymentStatus = async (h) => {
  return server.post("/VirtualPosResponse", { h });
};

export const PutIycizoResponse = async (
  token,
  response,
  agent = null,
  ip = null
) => {
  const config = { responseEncoding: "utf8" };

  if (agent) {
    config.headers = {
      "User-Agent": agent,
      CLIENT_IP: ip,
    };
  }

  const strParams = new URLSearchParams(response).toString();
  return server.post("/IyzicoResponse?token=" + token, strParams, config);
};
export const PutIycizoResponseWithoutReservation = async (
  basketid,
  response
) => {
  const config = { responseEncoding: "utf8" };

  const strParams = new URLSearchParams(response).toString();
  return server.post("/IyzicoResponseWithoutReservation", strParams, config);
};

export const UpdatePaymentCallBack = async (data) => {
  return server.post(
    "/IyzicoCallbackUpdate",
    "conversationId=" + data.conversationId + "&data=" + JSON.stringify(data)
  );
};

export const GetPaymentForm = async (id) => {
  return server.get("/PaymentForm?_=" + id);
};

export const SendSuccessMail = async (id) => {
  return server.get("/PaymentSuccess?_=" + id);
};
export const SendSuccessMailWithoutReservation = async (id) => {
  return server.get("/SendSuccessMailWithoutReservation?_=" + id);
};

export const CheckPaymentStatus = async (id) => {
  return server.get("/CheckPayment?_=" + id);
};
export const CheckValletPaymentStatus = async (id) => {
  return server.get("/CheckValletPayment?_=" + id);
};

export const GetPassengerInformation = async (token, id) => {
  return server.get("/PassengerInformation?token=" + token + "&_=" + id);
};

export const GetLastMinute = async () => {
  return server.get("/LastMinute");
};

export const GetDistance = async (id) => {
  return server.get("/Distance?EntityId=" + id);
};

export const HomeReviews = async () => {
  return server.get("/HomeReviews");
};
export const Whatsapp = async () => {
  return server.get("/Whatsapp");
};
export const HomeShortTerms = async () => {
  return server.get("/HomeShortTerms");
};
export const SearchBlog = async (text) => {
  return server.get("/Routing?url=blog-kesfet&search=" + text);
};

export const GetBlog = async (page, SearchText) => {
  return server.get("/GetBlog?page=" + page + "&search=" + SearchText);
};

export const SetRate = async (rate) => {
  return server.post("/announcement", "rate=" + rate);
};

export const SavePassengerInformation = async (token, id, data) => {
  return server.post(
    "/PassengerInformation?token=" + token + "&_=" + id,
    "data=" + JSON.stringify(data)
  );
};

export const SaveBankTransferForm = async (token, id, data) => {
  return server.post(
    "/SaveBankTransferForm?token=" + token + "&_=" + id,
    "data=" + JSON.stringify(data)
  );
};

export const SendOffer = async (data) => {
  return server.post("/SendOffer", "data=" + JSON.stringify(data));
};
export const SendReview = async (data) => {
  return server.post("/SendReview", "data=" + JSON.stringify(data));
};
export const SendContact = async (data) => {
  return server.post("/SendContact", "data=" + JSON.stringify(data));
};
export const CheckPromotioncode = async (data) => {
  return server.post("/CheckPromotioncode", data);
};
